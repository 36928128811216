import { useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setEditKeys } from "../../actions/product-information";
import { connect, useDispatch } from "react-redux";
import { setCustomerNumber } from "../../actions/customer-information";
import CircleLoader from "../../components/loader/circle-loader";
import LoadMockups from "../../services/load-mockups";

function Index(props) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const productInfo = props.productInformation;
  const loadedKeys = useRef(false);

  useEffect(() => {
    async function loadMocks(editKeys) {
      await LoadMockups(editKeys, dispatch, props.customerInformation.useRoster);
    }
    if (!loadedKeys.current && props.customerInformation.useRoster != null) {
      //Only run if there's no iframe
      if (window.location === window.parent.location) {
        loadedKeys.current = true;
        var params = {
          EditKeys: searchParams.get("editkeys") || searchParams.get("editKeys"),
          CustomerNumber: searchParams.get("accountId"),
        };

        params.EditKeys = params.EditKeys?.split(",");
        dispatch(setEditKeys([...new Set(params.EditKeys)]));
        dispatch(setCustomerNumber(params.CustomerNumber));
        loadMocks([...new Set(params.EditKeys)]);
      } else if (props.customerInformation.iframeLoaded === true) {
        loadedKeys.current = true;
        loadMocks(props.productInformation.editKeys);
      }
    }
  }, [
    dispatch,
    searchParams,
    navigate, loadedKeys,
    props.customerInformation.iframeLoaded,
    props.productInformation.editKeys,
    props.customerInformation.useRoster
  ]);

  useEffect(() => {
    if (props.productInformation.errors.length > 0) {
      navigate('/error');
    }
  }, [navigate, props.productInformation.errors]);

  useEffect(() => {
    if (productInfo.loadStatus === "loaded" && props.productInformation.errors.length === 0) {
      if (
        productInfo.editKeyTypes.playerName ||
        productInfo.editKeyTypes.playerNumber
      ) {
        navigate("/roster?mode=home");
      } else {
        navigate("/roster-stock?mode=home");
      }
    }
  }, [
    productInfo.loadStatus,
    navigate,
    productInfo.editKeyTypes.playerName,
    productInfo.editKeyTypes.playerNumber,
    productInfo,
    props.productInformation.errors.length 
  ]);

  return (
    <div>
      <CircleLoader/>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    customerInformation: state.customerInformation,
    productInformation: state.productInformation,
    roster: state.rosterInformation,
  };
};

export default connect(mapStateToProps)(Index);
