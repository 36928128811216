import { pushError, setEditKeyInfo, setLoadStatus, setStyleInfo } from "../actions/product-information";
import { addRosterItem } from "../actions/roster-information";
import { LoadRoster, getFinalPrices } from "./roster-helper";

export default async function LoadMockups(editKeys, dispatch, useRoster) {
    const testContentType = function(editKey, type) {
        return editKey?.data?.Locations.findIndex(x => x.contentType === type) > -1;
    }

    const fetchEditKeys = async(editKey) => {
        let result = await fetch(`${process.env.REACT_APP_ultimateBuilderAPIEndpoint}/configs/${editKey}?apiKey=${process.env.REACT_APP_ultimateBuilderAPIKey}`);
        let data = await result.json();
        if (data.length === 0) {
            dispatch(pushError(`Mockup ID: "${editKey}" was not found.`));
            return null;
        }
        return data[0];
    }

    const fetchStyleInfo = async(styleNum) => {
        let result = await fetch(`${process.env.REACT_APP_ultimateBuilderAPIEndpoint}/product/${styleNum}`);
        let data = await result.json();

        return data.products[0];
    }

    let loadObj = {
        editKeys: [...editKeys],
        editInfos: {},
        styles: [],
        styleInfos: {}
    }

    let stockMode = true;

    for(let i = 0; i < loadObj.editKeys.length; i++) {
        let ek = loadObj.editKeys[i];
        let eki = await fetchEditKeys(ek);
        if (eki === null) {
            continue;
        }

        var ei = {
            editKey: ek,
            data: eki,
            hasPlayerName: testContentType(eki, "Player Name"),
            hasPlayerNumber: testContentType(eki, "Player Number"),
            isHome: false,
            isAway: false,
            isTop: false,
            isBottom: false,
            isPrimary: eki.projectPrimaryConfigId
        };
        if (eki.groupType) {
            var f = eki.groupType.find(x => x.savedConfig === eki.configId);
            if (f) {
                if (f.type.includes('Home')) ei.isHome = true;
                if (f.type.includes('Away')) ei.isAway = true;
                if (f.type.includes('Top')) ei.isTop = true;
                if (f.type.includes('Bottom')) ei.isBottom = true;
            }
        }
        if (!ei.isHome && !ei.isAway && !ei.isTop && !ei.isBottom) {
            if (eki.data.productType) {
                ei.isHome = eki.data.productType.includes('Home');
                ei.isAway = eki.data.productType.includes('Away');
                ei.isTop = eki.data.productType.includes('Top');
                ei.isBottom = eki.data.productType.includes('Bottom');
            }
        }

        if (eki.groupType) {
            for(let pd in eki.groupType) {
                if (loadObj.editKeys.indexOf(eki.groupType[pd].savedConfig) === -1) {
                    loadObj.editKeys.push(eki.groupType[pd].savedConfig);
                }
            }
        } else if (eki.data.groupType) {
            for(let pd in eki.data.groupType) {
                if (eki.data.groupType[pd].savedConfig !== null && loadObj.editKeys.indexOf(eki.data.groupType[pd].savedConfig) === -1) {
                    loadObj.editKeys.push(eki.data.groupType[pd].savedConfig);
                }
            }
        }
        if (getFinalPrices(ei.data).length === 0) {
            continue; //If you pull in an companion item, it won't price out right so just ignore it
        }
        loadObj.editInfos[ek] = ei;
        if (testContentType(eki, "Player Name") || testContentType(eki, "Player Number")) {
            stockMode = false;
        }

        if (loadObj.styleInfos[eki.styleNum] === undefined) {
            let si = await fetchStyleInfo(eki.styleNum);
            loadObj.styleInfos[eki.styleNum] = {
                styleNum: eki.styleNum,
                data: si
            };
        }
    }
    if (loadObj.editKeys.length === 0) {
        dispatch(pushError('There were no mockups sent.'));
    }
    var isAddedToCart = false, isOrderedDesign = false;
    for(let ei in loadObj.editInfos) {
        if (loadObj.editInfos[ei].data.groupStatus === "In Cart") isAddedToCart = true;
        if (loadObj.editInfos[ei].data.isOrderedDesign) isOrderedDesign = true;
    }
    if (!isOrderedDesign && !process.env.REACT_APP_AllowAnyMockup) {
        dispatch(pushError(`Mockups must be ordered first before it can be filled in.`));
    } else if (!useRoster && isAddedToCart) {
        dispatch(pushError(`Mockup ID is already in the cart. Please edit this fill-in from the cart.`));
    }
    let roster = await LoadRoster(loadObj.editInfos, stockMode, useRoster);
    for(let mode in roster) {
        for(let r of roster[mode]) {
            dispatch(addRosterItem({
                mode: mode,
                item: r
            }))
        }
    }

    for( let ei in loadObj.editInfos) {
        dispatch(setEditKeyInfo(loadObj.editInfos[ei]));
    }
    for(let s in loadObj.styleInfos) {
        dispatch(setStyleInfo(loadObj.styleInfos[s]));
    }
    dispatch(setLoadStatus("loaded"))
    console.log("Mockup Info:", loadObj);
}