import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./roster.module.scss";
import { sortEditKeys } from "../../shared/sorting";
import { useDispatch } from "react-redux";
import {
  addRosterItem,
  removeRosterItem,
} from "../../actions/roster-information";
import {
  AddNewRosterItem,
  validateModeRoster,
} from "../../services/roster-helper";
import Header from "../../components/header/header";
import StyleInfo from "../select-styles/style-info";
import Footer from "../../components/footer/footer";
import RightArrowSVG from "../../Assets/icons/RightArrowSVG";
import RosterLine from "./roster-line";
import { setCurrentMode } from '../../actions/customer-information';

function RosterPage(props) {
  const dispatch = useDispatch();
  const [queryParameters] = useSearchParams();
  const mode = queryParameters.get("mode");
  let [oldMode, setOldMode] = useState(null);
  const rosterItems = props.roster.rosterItems[mode];
  const navigate = useNavigate();
  let [editKeyInfo, setEditKeyInfo] = useState(null);
  let [hasTops, setHasTops] = useState(null);
  let [hasBottoms, setHasBottoms] = useState(null);
  let [hasPlayerNumber, setHasPlayerNumber] = useState(false);
  let [hasPlayerName, setHasPlayerName] = useState(false);
  let [isLoaded, setIsLoaded] = useState(false);
  
  useEffect(() => {
    if (mode !== oldMode) {
      setEditKeyInfo(null);
      setOldMode(mode);
    }
  }, [mode, oldMode])

  useEffect(() => {
    dispatch(setCurrentMode(mode));
  }, [mode, dispatch])

  useEffect(() => {
    let editKeys = props.productInformation.editKeyInfo;
    let eki = {};
    for (const ki in editKeys) {
      if (!editKeys[ki].selected) continue;
      if (
        (mode === "home" && (editKeys[ki].isHome === true || props.productInformation.editKeyTypes.away === false)) ||
        (mode === "away" && editKeys[ki].isAway === true)
      ) {
        eki[ki] = editKeys[ki];
        if (editKeys[ki].hasPlayerNumber) setHasPlayerNumber(true);
        if (editKeys[ki].hasPlayerName) setHasPlayerName(true);
        if (editKeys[ki].isTop === true) setHasTops(true);
        if (editKeys[ki].isBottom === true) setHasBottoms(true);
      }
    }

    setEditKeyInfo(eki);
    setIsLoaded(true);
  }, [
    props.productInformation.editKeyInfo,
    props.productInformation.editKeyTypes.away,
    mode
  ]);

  let addRosterItemLocal = useCallback(() => {
    if (
      !(
        editKeyInfo &&
        editKeyInfo !== null &&
        Object.entries(editKeyInfo).length > 0
      )
    ) {
      return;
    }
    let newItem = AddNewRosterItem(
      props.roster.rosterIncrementer,
      editKeyInfo,
      hasTops,
      hasBottoms
    );
    dispatch(
      addRosterItem({
        mode: mode,
        item: newItem,
      })
    );
  }, [
    editKeyInfo,
    hasBottoms,
    hasTops,
    dispatch,
    props.roster.rosterIncrementer,
    mode,
  ]);

  function reviewSummaryClick() {
    if (validateModeRoster(mode, props.roster.rosterItems, true)) {
      navigate("/final-review");
    }
  }
  function awayRosterClick() {
    if (validateModeRoster(mode, props.roster.rosterItems, false)) {
      navigate("/roster?mode=away");
    }
  }

  let deleteItem = useCallback(
    (index) => {
      dispatch(removeRosterItem({ mode: mode, index: index }));
    },
    [dispatch, mode]
  );

  useEffect(() => {
    let isEmpty = false;
    if (isLoaded && mode === oldMode) {
      for (let index in rosterItems) {
        let ri = rosterItems[index];
        if (
          !ri.name &&
          !ri.number &&
          ri.gender === ri.genders[0] &&
          ri.parts.findIndex(
            (x) =>
              x.size !==
                x.sizes.find((y) => y.gender === ri.gender).sizes[0].size ||
              +x.qty !== 0
          ) === -1
        ) {
          isEmpty = true;
        }
      }
      if (!isEmpty) {
        addRosterItemLocal();
      }
    }
  }, [rosterItems, addRosterItemLocal, isLoaded, oldMode, mode]);

  let PartSectionHeader = () => {
    return (
      <>
        <th></th>
        <th 
          className={styles["roster-table--col-header"]}
          style={{ width: "90px" }}
        >Size*</th>
        <th 
          className={styles["roster-table--col-header"]}
          style={{ width: "90px" }}
        >Quantity</th>
        <th
          className={styles["roster-table--col-header"]}
          style={{ width: "100px" }}
        >
          Price
        </th>
      </>
    );
  };

  let RosterSectionHeader = (props) => {
    return (
      <>
        <th></th>
        <th colSpan={3} className={styles["roster-table--section-header"]}>
          {props.x.type.toUpperCase()}
        </th>
      </>
    );
  };

  return (
    <>
      <Header active="roster" />
      <div className={[styles["body"], styles["roster"]].join(" ")}>
        <div className={styles["left"]}>
          {editKeyInfo &&
            Object.entries(editKeyInfo)
              .sort((a, b) => sortEditKeys(a[1].data, b[1].data))
              .map((item) => (
                <StyleInfo
                  key={item[1].data.configId}
                  editKey={item[1].data.configId}
                  showDescription={false}
                  showSelection={false}
                />
              ))}
        </div>
        <div className={styles["right"]}>
          <h3>Roster List</h3>
          <table className={styles["roster-table"]}>
            <thead>
              {rosterItems[0].parts.length > 1 &&
              <tr>
                <th
                  colSpan={
                    hasPlayerName && hasPlayerNumber
                      ? 3
                      : hasPlayerName || hasPlayerNumber
                      ? 2
                      : 1
                  }
                  className={styles["roster-table--section-header"]}
                ></th>
                {rosterItems.length > 0 &&
                  rosterItems[0].parts.map((x) => (
                    <RosterSectionHeader x={x} key={x.type} />
                  ))}
              </tr>}
              <tr>
                {hasPlayerName && (
                  <th className={styles["roster-table--col-header"]}>Player Name*</th>
                )}
                {hasPlayerNumber && (
                  <th className={styles["roster-table--col-header"]}>Player Number*</th>
                )}
                <th className={styles["roster-table--col-header"]}>Gender*</th>
                {rosterItems.length > 0 &&
                  Array.apply(null, Array(rosterItems[0].parts.length)).map(
                    (p, i) => <PartSectionHeader key={i} />
                  )}
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  colSpan={12}
                  className={styles["roster-table--row-spacer"]}
                ></td>
              </tr>
              {rosterItems.map((ri, index) => {
                return (
                  <RosterLine
                    key={ri.id}
                    index={index}
                    rosterItem={ri}
                    hasPlayerName={hasPlayerName}
                    hasPlayerNumber={hasPlayerNumber}
                    deleteItem={deleteItem}
                    mode={mode}
                    ignoreValidation={rosterItems.length - 1 === index}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Footer>
        {props.productInformation.editKeyTypes.away === true &&
          mode === "home" && (
            <button type="button" className="footer-red" onClick={awayRosterClick}>
              Enter Away Roster <RightArrowSVG />
            </button>
          )}
        {(mode === "away" ||
          (props.productInformation.editKeyTypes.away === false &&
            mode === "home")) && (
          <button type="button" className="footer-red" onClick={reviewSummaryClick}>
            Review Summary <RightArrowSVG />
          </button>
        )}
      </Footer>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    customerInformation: state.customerInformation,
    productInformation: state.productInformation,
    roster: state.rosterInformation,
  };
};

export default connect(mapStateToProps)(RosterPage);
