function AddNewRosterItem(incrementer, editKeyInfo, hasTops, hasBottoms) {
  let newItem = {
    id: incrementer,
    name: null,
    number: null,
    gender: null,
    isValid: true,
    genders: [],
    parts: [],
  };

  let getSizes = function (genders, editKeyInfo) {
    return genders.map((g) => {
      return {
        gender: g.gender,
        styleNum: g.styleNum,
        title: g.title,
        sizes: getFinalPrices(editKeyInfo)
          .filter((x) => x.gender === g.gender && x.styleNum === g.styleNum)
          .map((x) => {
            return {
              size: x.size,
              price: x.price,
            };
          }),
      };
    });
  };

  let generatePart = (type, data, gender, genders, item) => {
    let part = {
      type: type,
      editKey: data,
      size: null,
      sizes: getSizes(genders, item),
      qty: 0,
    };
    part.size = part.sizes.find((x) => x.gender === gender).sizes[0].size;
    return part;
  };

  if (hasTops && hasBottoms) {
    let top = null,
      bottom = null;
    for (const ki in editKeyInfo) {
      if (editKeyInfo[ki].isTop === true) top = editKeyInfo[ki].data;
      if (editKeyInfo[ki].isBottom === true) bottom = editKeyInfo[ki].data;
    }
    let genders = [
      ...new Set(getFinalPrices(top).map((x) => x.gender)),
    ];
    let genderMap = [...new Map(getFinalPrices(top).map((x) => [x.gender, x])).values()];
    genders = genders.filter((x) => {
      return (
        getFinalPrices(bottom).findIndex((y) => y.gender === x) > -1
      );
    });
    newItem.genders = genders;
    newItem.gender = genders[0];

    newItem.parts.push(
      generatePart("top", top, newItem.gender, genderMap, top)
    );
    genderMap = [...new Map(getFinalPrices(bottom).map((x) => [x.gender, x])).values()];
    newItem.parts.push(
      generatePart("bottom", bottom, newItem.gender, genderMap, bottom)
    );
  } else {
    let item = Object.entries(editKeyInfo)[0][1].data;
    let genders = [
      ...new Set(getFinalPrices(item).map((x) => x.gender)),
    ];
    let genderMap = [...new Map(getFinalPrices(item).map((x) => [x.gender, x])).values()];
    newItem.genders = genders;
    newItem.gender = genders[0];

    newItem.parts.push(
      generatePart("", item, newItem.gender, genderMap, item)
    );
  }
  return newItem;
}

function getPrice(index, getValues, part, mode = "full") {
    if (!part) return "$0.00";
  let gender = getValues(`gender`);
  let size = getValues(`parts.${index}.size`);
  // let qty = getValues(`parts.${index}.qty`);
  let price = part.sizes
    .find((x) => x.gender === gender)
    .sizes.find((x) => x.size === size)?.price || null;
  if (price === null) return null;
  let decoPrice = 
    ((part.editKey.decorationPricing?.totalCents || 0) / 100) + 
    (part.editKey.braid?.part ? Number(part.editKey.braid?.braidPrice / 100) : 0) +
    (part.editKey.stitch?.customStitching ? Number(part.editKey.stitch?.price / 100) : 0);
  //calculated price
  //return (+(Math.round(qty * price + "e+2") + "e-2")).toFixed(2);
  if (mode === "full") {
    return (+(Math.round((price + decoPrice) + "e+2") + "e-2")).toFixed(2);
  } else if (mode === "deco") {
    return (+(Math.round(decoPrice + "e+2") + "e-2")).toFixed(2);
  } else if (mode === "base") {
    return (+(Math.round(price + "e+2") + "e-2")).toFixed(2);
  }
  return 0;
}

function getFinalPrices(data) {
  if (!data.groupType) return data.data.finalSizePrice;
  return data.data.finalSizePrice.filter(x => {
    return data.groupType.find(y => y.savedConfig === data.configId)?.genderStyle.indexOf(x.styleNum) > -1
  });
}

function validateModeRoster(mode, rosterItems, finalReview) {
  if (
    rosterItems[mode].findIndex(
      (x, i) => i !== rosterItems[mode].length - 1 && !x.isValid
    ) > -1
  ) {
    alert("There is an error with one of the records.");
    return false;
  } else if (finalReview && mode === "away") {
    var homeRoster = rosterItems["home"];
    if (
      homeRoster.findIndex(
        (x, i) => i !== homeRoster.length - 1 && !x.isValid
      ) > -1
    ) {
      alert("There is an issue with the home roster.");
      return false;
    }
  }
  if (finalReview) {
    let hasQty = false;
    for (let index in rosterItems) {
      let ros = rosterItems[index];
      if (ros.findIndex((x) => x.parts.findIndex((y) => y.qty > 0) > -1) > -1) {
        hasQty = true;
        break;
      }
    }
    if (!hasQty) {
      alert("Must have at least one piece to order.");
      return false;
    }
  }
  return true;
}

const fetchRoster = async(configId) => {
  let rosters = {};
  try {
    let result = await fetch(`${process.env.REACT_APP_ultimateBuilderAPIEndpoint}/rosterInfo/?primaryConfigId=${configId}`);
    let data = await result.json();
    if (!data.RosterInfo) {
      return {
        null: []
      };
    }
    data.RosterInfo.forEach(ri => {
      ri.rosterData.forEach(x => {
        if (!rosters[x.groupType || null]) {
          rosters[x.groupType || null] = [];
        }
        rosters[x.groupType || null].push(x);
      })
    });
  } catch (e) {
    console.error(e);
  }
    return rosters;
}

async function LoadRoster(editInfos, isStockMode, useRoster) {
  //if isStockMode == true then do a normal stockroster ignoring names/numbers
  let usedKeys = [];
  let rosterIncrementer = 0;
  let rosters = {};
  let finalRosters = {
    home: [],
    away: []
  };
  if (useRoster) {  
    for(let ei in editInfos) {
      if (editInfos[ei].data.projectData) {
        rosters = await fetchRoster(editInfos[ei].data.projectData.find(x => x.projectPrimaryConfigId).configId);
      } else {
        rosters = await fetchRoster(ei);
      }
      break;
    }
  }
  console.log("Roster loads:", rosters);
  if (!isStockMode) {
    for(let ei in editInfos) {
      if (usedKeys.indexOf(ei) > -1) continue;

      let eki = {};
      let hasTops = false, hasBottoms = false;
      for(var ei2 in editInfos) {
        if (editInfos[ei].isHome === editInfos[ei2].isHome && editInfos[ei].isAway === editInfos[ei2].isAway) {
          eki[ei2] = editInfos[ei2];
          if (eki[ei2].isTop) {
            hasTops = true;
          } else if (eki[ei2].isBottom) {
            hasBottoms = true;
          }
        }
      }
      let rosterItem = AddNewRosterItem(rosterIncrementer, eki, hasTops, hasBottoms);
      let rowIndicies = [Number.MAX_SAFE_INTEGER];
      for(let p of rosterItem.parts) {
        rowIndicies = new Set([...rowIndicies, ...(rosters[p.editKey.data.productType]?.map(x => x.rowIndex) || [])]);
      }
      rowIndicies = Array.from(rowIndicies);
      rowIndicies.sort();
      
      for(let r of rowIndicies) {
        let c = structuredClone(rosterItem);
        c.id = +rosterIncrementer;
        let isAway = false;
        for(let p of c.parts) {
          if (eki[p.editKey.configId].isAway) isAway = true;
          let ri = rosters[p.editKey.data.productType]?.find(x => x.rowIndex === r);
          p.qty = ri?.quantity || 0;
          p.size = ri?.size || p.sizes.find(x => x.gender === c.gender).sizes[0].size;
          c.gender = ri?.gender || c.gender || c.genders[0];
          c.name = ri?.name || c.name || null;
          c.number = ri?.number || c.number || null;
        }
        finalRosters[isAway ? 'away' : 'home'].push(c);
        rosterIncrementer++;
      }
      for(let i = 0; i < rosterItem.parts.length; i++) {
        usedKeys.push(rosterItem.parts[i].editKey.configId);
      }
    }
  } else {
    let i = 0;
    for (const ki in editInfos) {
      let gfp = getFinalPrices(editInfos[ki].data);
      for (const sp of gfp) {
        var r = rosters[editInfos[ki].data.data.productType]?.find(x => 
          x.gender === sp.gender &&
          x.size === sp.size);
        let newItem = {
          id: i,
          name: null,
          number: null,
          isValid: true,
          gender: sp.gender,
          genders: [sp.gender],
          parts: [
            {
              type: "",
              editKey: editInfos[ki].data,
              size: sp.size,
              sizes: [
                {
                  gender: sp.gender,
                  styleNum: sp.styleNum,
                  title: sp.title,
                  sizes: [
                    {
                      size: sp.size,
                      price: sp.price,
                    },
                  ],
                },
              ],
              qty: r?.quantity || 0,
            },
          ],
        };
        finalRosters[editInfos[ki].isAway ? 'away' : 'home'].push(newItem);
        i++;
      }
    }
  }
  console.log("Rosters:",finalRosters);
  return finalRosters;
}

async function SaveRoster(Mockup, Roster, Heights) {

  let rosterModel = {
    "rosterName": "test",
    "rosters": [
      {
        "items": []
      }
    ],
    "heightAdjust": []
  };

  let editKey = null;

  let processModes = (mode, roster) => {
    for(let rosterItem of roster) {
      for(let part of rosterItem.parts) {
        if (part.qty * 1 === 0) continue;
        if (editKey === null && part.editKey.projectData) {
          editKey = part.editKey.projectData.find(x => x.projectPrimaryConfigId === true).configId;
        } else if (editKey === null) {
          editKey = part.editKey.configId;
        }

        let groupType = part.editKey.data.productType;
        let item = {
          sku: part.editKey.data.finalSizePrice.find(x => x.styleNum === part.sizes.find(x => x.gender === rosterItem.gender).styleNum && x.gender === rosterItem.gender && x.size === part.size).sku,
          name: rosterItem.name,
          size: part.size,
          price: part.sizes.find(x => x.gender === rosterItem.gender).sizes.find(x => x.size === part.size).price,
          gender: rosterItem.gender,
          number: rosterItem.number,
          preview: false,
          quantity: part.qty * 1,
          rowIndex: rosterItem.id,
          styleNum: part.sizes.find(x => x.gender === rosterItem.gender).styleNum
        }
        if (groupType) {
          item.groupType = groupType;
        }
        rosterModel.rosters[0].items.push(item);
      }
    }
  }

  processModes('Home', Roster.rosterItems.home);
  processModes('Away', Roster.rosterItems.away);

  for(let err of Heights) {
    rosterModel.heightAdjust.push({
      type: err.data.type,
      gender: err.styleNum,
      height: err.data.height,
      location: err.data.location,
      genderVal: err.data.genderVal
    })
  }
  let result = await fetch(`${process.env.REACT_APP_ultimateBuilderAPIEndpoint}/save-roster?configId=${editKey}&edit=false&rosterType=Fill-In`, {
    method: 'PUT',
    body: JSON.stringify(rosterModel),
    headers: {
      "Content-Type": "application/json"
    }
  });
  let data = await result.json();
  console.log(data);
  return data;
}

export { 
  AddNewRosterItem,
  validateModeRoster,
  getPrice,
  getFinalPrices,
  LoadRoster,
  SaveRoster
};
