import { createSlice } from "@reduxjs/toolkit";

export const rosterInformation = createSlice({
    name: 'roster-information',
    initialState: {
        rosterItems: {
            home: [],
            away: []
        },
        rosterIncrementer: 1
    },
    reducers: {
        addRosterItem: (state, action) => {
            state.rosterItems[action.payload.mode].push(action.payload.item);
            state.rosterIncrementer += 1;
        },
        removeRosterItem: (state, action) => {
            state.rosterItems[action.payload.mode].splice(action.payload.index, 1);
        },
        emptyRosterItems: (state, action) => {
            state.rosterItems[action.payload.mode] = state.rosterItems[action.payload.mode].filter(x => {
                return x.parts.length ===  action.payload.parts
            });
        },
        setRosterItemValue: (state, action) => {
            state.rosterItems[action.payload.mode][action.payload.index][action.payload.field] = action.payload.value;
        },
        setRosterItemPartValue: (state, action) => {
            state.rosterItems[action.payload.mode][action.payload.index].parts[action.payload.partIndex][action.payload.field] = action.payload.value;
        }
    }
});

export const {
    addRosterItem,
    removeRosterItem,
    emptyRosterItems,
    setRosterItemValue,
    setRosterItemPartValue
} = rosterInformation.actions;

export default rosterInformation.reducer;