import { connect } from "react-redux";
import styles from './circle-loader.module.scss';
import { AiOutlineLoading3Quarters } from "react-icons/ai";

function CircleLoader(props) {

    return (<div className={styles["loader-holder"]}>
        <AiOutlineLoading3Quarters className={styles["circle-loader"]}/>
    </div>)
}

const mapStateToProps = (state) => {
    return {
        customerInformation: state.customerInformation,
        productInformation: state.productInformation
    }
}

export default connect(mapStateToProps)(CircleLoader);