import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import SelectStylesPage from "./pages/select-styles/select-styles";
import RosterPage from "./pages/roster/roster";
import FinalReviewPage from "./pages/final-review/final-review";
import Index from "./pages/index";
import RosterStockPage from "./pages/roster-stock/roster-stock";
import ErrorPage from "./pages/error/error";
import ScrollToTop from "./shared/scroll-helper";
import { useCallback, useEffect, useState } from "react";
import { setCustomerNumber, setIframeLoaded, setUseRoster } from "./actions/customer-information";
import { setEditKeys } from "./actions/product-information";
import { clearMessage } from "./actions/iframe";

function App(props) {

  var IframeMessengerSdk = window.IframeMessengerSdk;
  var [isEmbeddedInIframe, setembeddediniframe] = useState(false);
  var [messenger, setMessenger] = useState();
  const dispatch = useDispatch();

  var initIframeMessenger = useCallback(() => {
    let local_isEmbeddedInIframe = !(window.location === window.parent.location);
    console.log(`iframe embed mode:  ${local_isEmbeddedInIframe}`);

    if (local_isEmbeddedInIframe !== isEmbeddedInIframe) {
      if (typeof IframeMessengerSdk !== 'undefined') {
        const publisher = (window && window.parent) || window;
        const subscriber = window;
        if (publisher && subscriber) {
          let messenger = new IframeMessengerSdk({
            publisher,
            subscriber,
          });
          messenger.on('initData', (initData) => {
            console.log(
              'if-messenger.on.initData: ' + JSON.stringify(initData)
            );
            dispatch(setCustomerNumber(initData.accountId));
            dispatch(setUseRoster(initData.useRoster || false))
            dispatch(setEditKeys([...new Set(initData.editkeys.split(','))]));
            dispatch(setIframeLoaded(true));
          });
          setMessenger(messenger);
        }
      } else {
        console.warn('iframe-messenger-sdk not loaded');
      }
      setembeddediniframe(local_isEmbeddedInIframe);
    }
  }, [IframeMessengerSdk, dispatch, isEmbeddedInIframe]);

  useEffect(() => {
    initIframeMessenger();
    if (!isEmbeddedInIframe || !messenger) {
      dispatch(setCustomerNumber('U018761'))
      dispatch(setUseRoster(true))
    }
  }, [isEmbeddedInIframe, messenger, initIframeMessenger, IframeMessengerSdk, dispatch]);

  useEffect(() => {
    if(isEmbeddedInIframe && messenger && props.iframe.message != null) {
      dispatch(clearMessage());
      let {action, data, callback} = props.iframe.message;
      console.log("if-messenger.send.action: " + JSON.stringify({action, data}));
      messenger.sendMessage("action", {action, data});
      if (callback) {
        callback(action, data);
      }
    }
  }, [props.iframe.message, messenger, dispatch, isEmbeddedInIframe])

  function isMockupsSet() {
    return props.productInformation.editKeys.length > 0;
  }

  return (
    <div className="App">
      <BrowserRouter>
      <ScrollToTop/>
        <Routes>
          <Route index element={<Index />} />
          <Route
            path="select-styles"
            element={
              isMockupsSet() ? <SelectStylesPage /> : <Navigate to="/error" />
            }
          />
          <Route
            path="roster"
            element={isMockupsSet() ? <RosterPage /> : <Navigate to="/error" />}
          />
          <Route
            path="roster-stock"
            element={
              isMockupsSet() ? <RosterStockPage /> : <Navigate to="/error" />
            }
          />
          <Route
            path="final-review"
            element={
              isMockupsSet() ? <FinalReviewPage /> : <Navigate to="/error" />
            }
          />
          <Route path="error" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    customerInformation: state.customerInformation,
    productInformation: state.productInformation,
    roster: state.rosterInformation,
    iframe: state.iframe
  };
};

export default connect(mapStateToProps)(App);
