import { createSlice } from "@reduxjs/toolkit";

export const productInformation = createSlice({
    name: 'product-information',
    initialState: {
        editKeys: [],
        editKeyInfo: {},
        loadStatus: 'loading',
        styles: [],
        styleInfo: {},
        errors: [],
        editKeyTypes: {
            home: false,
            away: false,
            top: false,
            bottom: false,
            playerName: false,
            playerNumber: false
        }
    },
    reducers: {
        pushError: (state, action) => {
            state.errors.push(action.payload);
        },
        setEditKeys: (state, action) => {
            state.editKeys = action.payload.filter(x => x !== null && x !== undefined && x.length > 0);
        },
        setEditKeyInfo: (state, action) => {
            state.editKeyInfo[action.payload.editKey] = {
                data: action.payload.data,
                selected: true,
                hasPlayerNumber: action.payload.hasPlayerNumber,
                hasPlayerName: action.payload.hasPlayerName,
                isHome: action.payload.isHome,
                isAway: action.payload.isAway,
                isTop: action.payload.isTop,
                isBottom: action.payload.isBottom
            }
            state.editKeyTypes.playerName = action.payload.hasPlayerName || state.editKeyTypes.playerName;
            state.editKeyTypes.playerNumber = action.payload.hasPlayerNumber || state.editKeyTypes.playerNumber;

            var hasHome = false, hasAway = false, hasTop = false, hasBottom = false;
            for(let key in state.editKeyInfo) {
                let editKey = state.editKeyInfo[key];
                if (editKey.isHome) hasHome = true;
                if (editKey.isAway) hasAway = true;
                if (editKey.isTop) hasTop = true;
                if (editKey.isBottom) hasBottom = true;
            }
            state.editKeyTypes.home = hasHome;
            state.editKeyTypes.away = hasAway;
            state.editKeyTypes.top = hasTop;
            state.editKeyTypes.bottom = hasBottom;
        },
        setEditKeySelection: (state, action) => {
            state.editKeyInfo[action.payload.editKey].selected = action.payload.selected;
        },
        setLoadStatus: (state, action) => {
            state.loadStatus = action.payload;
        },
        setStyleInfo: (state, action) => {
            state.styleInfo[action.payload.styleNum] = action.payload.data;
        }
    }
})

export const {
    setEditKeys,
    setEditKeyInfo,
    setLoadStatus,
    setStyleInfo,
    setEditKeySelection,
    pushError
} = productInformation.actions;

export default productInformation.reducer;