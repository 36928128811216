const previewPriority_Front = ["PDF_Front","Front", "Right"];
const previewPriority_Back = ["PDF_Back", "Back", "Left"];

const getPreviewImage = function(data, position, side) {
    let preview = null;
    side = side || "Outside";
    var previewPosition = position === "front" ? previewPriority_Front : previewPriority_Back;
    previewPosition.every(pp => {
        preview = data.previews.find(p => {
            return p.view === pp && p.productView === side;
        })
        if (preview) {
            return false;
        }
        return true;
    });
    return preview?.url;
}

export {
    getPreviewImage
};