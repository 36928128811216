import { Suspense, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getPreviewImage } from "../../shared/pdf-previews";
import StyleHeader from "../../components/style-header/style-header";
import styles from "./style-info.module.scss";

function StyleInfo(props) {
  const [styleInfo, setStyleInfo] = useState(null);
  const [editKeyInfo, setEditKeyInfo] = useState(null);
  const showDescription = props.showDescription ?? true;
  const showSelection = props.showSelection ?? true;
  const showHeader = props.showHeader ?? true;
  const [isReversible, setReversible] = useState(false);
  const [showInside, setShowInside] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    let eki = props.productInformation.editKeyInfo[props.editKey];
    if (eki && eki.data) {
      let si = props.productInformation.styleInfo[eki.data.styleNum];

      setStyleInfo(si);
      setEditKeyInfo(eki);
      setReversible(si.metadata.Reversible === 'true');
    }
  }, [
    dispatch,
    props.editKey,
    props.productInformation.editKeyInfo,
    props.productInformation.styleInfo,
  ]);

  const setSelectionState = function (e) {
    props.setSelectionState(props.editKey, e.target.checked);
  };

  const reverseViews = () => {
    setShowInside(!showInside);
  }

  return (
    <Suspense>
    <div className={styles["style-info"]}>
      {styleInfo && editKeyInfo && (
        <>
          {showHeader && <StyleHeader
            editKey={props.editKey}
            styleNum={editKeyInfo.data.styleNum}
          />}
          {showDescription && (
            <>
              <div className={styles["style-info--separator"]}></div>
              {showSelection && (
                <>
                  <b>Product Description</b>{" "}
                  <input
                    type="checkbox"
                    onChange={setSelectionState}
                    checked={editKeyInfo.selected}
                  />
                </>
              )}
              <ul>
                {styleInfo.metadata["Style Features"]
                  .split(";")
                  .map((sf, i) => {
                    return (
                      <li key={i} className={styles["style-info--description"]}>
                        {sf.trim()}
                      </li>
                    );
                  })}
              </ul>
              <div className={styles["style-info--separator"]}></div>
            </>
          )}
          <div className={styles["style-info--image-holder"]}>
            {isReversible && (
              <div className={styles["style-info--reversible-button"]} onClick={reverseViews}>
                <img src={getPreviewImage(editKeyInfo.data, "front", showInside !== true ? "Inside" : "Outside")} alt="show reverse"/>
                <span>{showInside !== true ? "Inside" : "Outside"} View</span>
              </div>
            )}
            <div className={styles["style-info--image-holder--view"]}>
              <img src={getPreviewImage(editKeyInfo.data, "front", showInside === true ? "Inside" : "Outside")} alt="mockup preview" />
            </div>
            <div className={styles["style-info--image-holder--view"]}>
              <img src={getPreviewImage(editKeyInfo.data, "back", showInside === true ? "Inside" : "Outside")} alt="mockup preview" />
            </div>
          </div>
        </>
      )}
    </div>
    </Suspense>
  );
}

const mapStateToProps = (state) => {
  return {
    customerInformation: state.customerInformation,
    productInformation: state.productInformation,
  };
};

export default connect(mapStateToProps)(StyleInfo);
