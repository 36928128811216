import { Suspense, useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Loader from "../../components/loader/loader";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import { sortEditKeys } from "../../shared/sorting";
import { getPreviewImage } from "../../shared/pdf-previews";
import classes from "./final-review.module.scss";
import StyleHeader from "../../components/style-header/style-header";
import ShoppingCartSVG from "../../Assets/icons/ShoppingCartSVG";
import { sendMessage } from "../../actions/iframe";
import groupBy from 'core-js/actual/object/group-by';
import alertIcon from '../../Assets/icons/alert.png';
import { SaveRoster } from "../../services/roster-helper";
import StyleInfo from "../select-styles/style-info";
import { setLoadStatus } from "../../actions/product-information";

function FinalReviewPage(props) {
  let [activeItem, setActiveItem] = useState(null);
  const dispatch = useDispatch();

  const setActiveItemByEditKey = useCallback(
    (editKey) => {
      setActiveItem(props.productInformation.editKeyInfo[editKey].data);
    },
    [props.productInformation.editKeyInfo]
  );

  useEffect(() => {
    setActiveItemByEditKey(
      Object.entries(props.productInformation.editKeyInfo).sort((a, b) =>
        sortEditKeys(a[1].data, b[1].data)
      )[0][1].data.configId
    );
  }, [props.productInformation.editKeyInfo, setActiveItemByEditKey]);

  function getSubPreviewName(data) {
    var groupType = null;
    if (data.groupType) {
      groupType = data.groupType.find(
        (x) => x.savedConfig === data.configId
      ).type;
    }
    return groupType;
  }
  const validateAssets = useCallback((part, styleNum, line) => {
    let errors = [];
    let locHeights = props.productInformation.styleInfo[part.editKey.styleNum].value['All Styles'].find(as => as.relStyle === styleNum.styleNum).styleDecLocHeights;
    if (locHeights.length > 0) {
      for(let ai in part.editKey.assets) {
        let a = part.editKey.assets[ai];
        let h = +a.height.replace(/\D/, "")
        let loc = locHeights.find(x => x.locationName === a.location);
        if (loc && (h > +loc.maxHeight || h < +loc.minHeight)) {
          errors.push({
            configId: part.editKey.configId,
            styleNum: styleNum.styleNum,
            errorType: 'height',
            message: <p><b>{a.contentType}</b> in <b>{a.location}</b> has changed from <span>{h}"</span> to <span>{loc.maxHeight}"</span> for <b>{styleNum.styleNum} ({styleNum.gender})</b></p>,
            data: {
              height: `${loc.maxHeight}"`,
              location: a.location,
              genderVal: line.gender,
              type: part.editKey.data.productType
            }
          });
        }
      }
    }
    return errors;
  }, [props.productInformation.styleInfo]);
  const getActiveRoster = useCallback(() => {
    let roster = [];
    let hasPlayerName = false,
      hasPlayerNumber = false;
    let index = 0;
    let errors = [];

    for (const type in props.roster.rosterItems) {
      for (const lineIndex in props.roster.rosterItems[type]) {
        let line = props.roster.rosterItems[type][lineIndex];
        for (const partIndex in line.parts) {
          let part = line.parts[partIndex];
          if (+part.qty === 0) continue;
          let itemPrice = part.sizes
            .find((x) => x.gender === line.gender)
            .sizes.find((x) => x.size === part.size).price;
          let decoPrice = 
            ((part.editKey.decorationPricing?.totalCents || 0) / 100) + 
            (part.editKey.braid?.part ? Number(part.editKey.braid?.braidPrice / 100) : 0) +
            (part.editKey.stitch?.customStitching ? Number(part.editKey.stitch?.price / 100) : 0);
          let styleNum = part.sizes.find((x) => x.gender === line.gender);
          let r = roster.find(
            (x) =>
              x.styleNum === styleNum.styleNum &&
              x.editKey === part.editKey.configId
          );
          if (!r) {
            errors = errors.concat(validateAssets(part, styleNum, line));
            r = {
              styleNum: styleNum.styleNum,
              design: part.editKey.data.design?.metadara?.Code,
              editKey: part.editKey.configId,
              parentStyle: part.editKey.styleNum,
              title: styleNum.title,
              previewUrl: getPreviewImage(part.editKey, "front"),
              items: [],
              isProject: part.editKey.projectId !== null,
              projectId: part.editKey.projectId,
              projectConfigId: part.editKey.projectData?.find(x => x.projectPrimaryConfigId === true).configId
            };
            roster.push(r);
          }
          r.items.push({
            index: index * 1,
            name: line.name,
            number: line.number,
            size: part.size,
            qty: part.qty,
            price: itemPrice,
            decCharge: decoPrice,
            gender: line.gender,
            type: part.editKey.data.productType
          });
          hasPlayerName = line.name !== null || hasPlayerName;
          hasPlayerNumber = line.number !== null || hasPlayerNumber;
          index++;
        }
      }
    }
    roster = roster.filter((x) => x.items.length > 0);
    return {
      hasPlayerName,
      hasPlayerNumber,
      roster,
      errors
    };
  }, [props.roster.rosterItems, validateAssets]);
  const getTotals = useCallback(
    (roster) => {
      let price = 0,
        quantity = 0;
      let items =
        roster?.items ??
        [].concat.apply(
          [],
          getActiveRoster().roster.map((x) => x.items)
        );
      for (const line in items) {
        price += +items[line].qty * (+items[line].price +items[line].decCharge);
        quantity += +items[line].qty;
      }
      return {
        price,
        quantity,
      };
    },
    [getActiveRoster]
  );
  const getActiveCounts = useCallback(
    (roster, size) => {
      let quantity = 0;
      for (const line in roster.items) {
        if (roster.items[line].size === size)
          quantity += +roster.items[line].qty;
      }
      return quantity;
    },
    []
  );
  const getActiveSizes = useCallback(
    (roster) => {
      var data = props.productInformation.editKeyInfo[roster.editKey].data;
      //if (!data.groupType) return data.data.finalSizePrice;
      let ret = data.data.finalSizePrice.filter((x) => {
        if (x.styleNum !== roster.styleNum) return false;
        if (data.groupType) {
          return (
            data.groupType
              .find((x) => x.savedConfig === data.configId)
              .genderStyle.indexOf(x.styleNum) > -1
          );
        }
        return true;
      });
      return [...new Set(ret.map((x) => x.size))];
    },
    [props.productInformation.editKeyInfo]
  );
  
  function formatCurrency(value) {
    return (+(Math.round(value + "e+2") + "e-2")).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  async function addToCart() {
    dispatch(setLoadStatus("Saving the roster"));
    let output = await SaveRoster(props.productInformation, props.roster, getActiveRoster().errors.filter(x => x.errorType === "height"));

    dispatch(setLoadStatus("Adding items to the cart"));
    let cartAddInfo = {
      style: null,
      design: null,
      groupItem: false,
      isProject: false,
      projectId: null,
      projectConfigId: null,
      isStock: false,
      configId: null,
      colors: null,
      previewUrl: null,
      customData: null,
      totPriceAdjust: 0,
      sizeQuantities: [],
      decorations: [],
      isFillIn: "True"
    };
    let relatedOrdsToUpdate = {};

    let firstItem = null;
    let count = 0;
    let totPriceAdjust = 0;
    let sizeQuantities = [];

    for (const type in props.roster.rosterItems) {
      for (const lineIndex in props.roster.rosterItems[type]) {
        let line = props.roster.rosterItems[type][lineIndex];
        for (const partIndex in line.parts) {
          let part = line.parts[partIndex];
          if (+part.qty === 0) continue;
          if (part.editKey.projectPrimaryConfigId) firstItem = part.editKey;
        }
      }
    }

    if (firstItem == null) {
      for(let prop in props.productInformation.editKeyInfo) {
        firstItem = props.productInformation.editKeyInfo[prop].data;
        break;
      }
    }
    
    let mainStyle = props.productInformation.styleInfo[firstItem.styleNum];
    cartAddInfo.style = mainStyle.metadata.Style;
    cartAddInfo.design = firstItem.data.design?.metadata?.Code;
    cartAddInfo.groupItem = firstItem.isGroupItem;
    cartAddInfo.isProject = firstItem.projectId !== null;
    cartAddInfo.configId = firstItem.projectData?.find(x => x.projectPrimaryConfigId === true).configId ?? firstItem.configId;
    cartAddInfo.projectConfigId = cartAddInfo.isProject ? cartAddInfo.configId : null;
    cartAddInfo.projectId = firstItem.projectId;
    cartAddInfo.isStock = mainStyle.metadata.value?.Type === "Stock";
    cartAddInfo.colors = cartAddInfo.isStock
      ? mainStyle.color
        ? mainStyle.color.length > 0
          ? mainStyle.color[0].code
          : null
        : "OX/BKVF"
      : "custom";
    cartAddInfo.previewUrl = getPreviewImage(firstItem, "front");

    for (const type in props.roster.rosterItems) {
      for (const lineIndex in props.roster.rosterItems[type]) {
        let line = props.roster.rosterItems[type][lineIndex];
        for (const partIndex in line.parts) {
          let part = line.parts[partIndex];
          if (+part.qty === 0) continue;
          if (part.editKey.projectPrimaryConfigId) firstItem = part.editKey;
          let rosterConfigId = output.find(x => x.styleNum === part.sizes.find(x => x.gender === line.gender).styleNum && (x.groupId === part.editKey.groupId || x.groupType === part.editKey.groupType));
          let finalSizePrice = part.editKey.data.finalSizePrice.find(x => x.styleNum === part.sizes.find(x => x.gender === line.gender).styleNum && x.gender === line.gender && x.size === part.size);
          sizeQuantities.push({
            sku: finalSizePrice.sku,
            quantity: +part.qty,
            size: part.size,
            configId: rosterConfigId?.configId || part.editKey.configId,
            style: rosterConfigId?.styleNum || part.editKey.data.styleNum,
            gender: line.gender,
            type: part.editKey.data.productType,
            previewUrl: getPreviewImage(part.editKey, "front"),
            groupId: rosterConfigId?.groupId || part.editKey.groupId
          });
          if (!relatedOrdsToUpdate[part.editKey.configId]) {
            relatedOrdsToUpdate[part.editKey.configId] = part.editKey;
          }

          if (!cartAddInfo.isProject) {
            totPriceAdjust += part.editKey.data.decorationPricing?.totalCents || 0;
          }
          
          for(let decor of part.editKey.data.decorationPricing?.entities || []) {
            let enityType = decor.entityType;
            if (decor.entityType === "Custom Text") {
              enityType = "custom-text";
            } else if (decor.entityType === "Player Name") {
              enityType = "player-name";
            } else if (decor.entityType === "Player Number") {
              enityType = "player-number";
            } else if (decor.entityType === "Graphics") {
              enityType = "custom-art";
            }
            cartAddInfo.decorations.push({
              decorationId: count,
              entityType: enityType,
              decorationType: decor.decorationMethod.toLowerCase(),
              colors: cartAddInfo.colors,
              previewUrl: null,
              priceAdjust: decor.priceCents,
            });
            count += 1;
          }
    
          if (part.editKey.data.stitch?.customStitching === true) {
            cartAddInfo.decorations.push({
              decorationId: count,
              entityType: "stitching",
              decorationType: "stitching",
              colors: part.editKey.data.stitch.stitchColorCode,
              previewUrl: null,
              priceAdjust: part.editKey.data.stitch.price,
            });
            count += 1;
          }
    
          if (part.editKey.data.braid?.part) {
            cartAddInfo.decorations.push({
              decorationId: count,
              entityType: "braid",
              decorationType: "braid",
              colors: part.editKey.data.braid.color,
              previewUrl: null,
              priceAdjust: part.editKey.data.braid.braidPrice,
            });
            count += 1;
          }
        }
      }
    }
    

    
    cartAddInfo.totPriceAdjust = totPriceAdjust;

    for(let i in sizeQuantities) {
      let newsq = {
        sku: sizeQuantities[i].sku,
        quantity: sizeQuantities[i].quantity,
        size: sizeQuantities[i].size,
        configId: sizeQuantities[i].configId,
        style: sizeQuantities[i].style,
        gender: sizeQuantities[i].gender
      };
      if (cartAddInfo.isProject) {
        newsq.type = sizeQuantities[i].type;
        newsq.previewUrl = sizeQuantities[i].previewUrl;
      }
      if (cartAddInfo.groupItem) {
        newsq.groupId = sizeQuantities[i].groupId;
      }
      cartAddInfo.sizeQuantities.push(newsq);
    }

    dispatch(sendMessage({
      action: "add-to-cart",
      data: [cartAddInfo],
      callback: async (action, data) => {
        console.log(action, data);
          dispatch(setLoadStatus("Redirecting to the Shopping Cart"));
          await UpdateStatus(firstItem.data.configId, mainStyle.name, cartAddInfo, relatedOrdsToUpdate);
      }
    }))
    console.log(cartAddInfo, firstItem, mainStyle);
    if (!props.customerInformation.iframeLoaded) {
      //Not in an iframe, close loader
      // Only for debugging purposes
      // await UpdateStatus(firstItem.data.configId, mainStyle.name, cartAddInfo, relatedOrdsToUpdate);
      dispatch(setLoadStatus("loaded"));
    }
  }

  async function UpdateStatus(configId, style, data, relatedOrdsToUpdate) {
    let messageData = Object.assign({
      success: true
    }, data);
    await fetch(`${process.env.REACT_APP_ultimateBuilderAPIEndpoint}/ubdata?apiKey=${process.env.REACT_APP_ultimateBuilderAPIKey}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        configId,
        style,
        message: {
          data: JSON.stringify(messageData)
        }
      })
    });
    for(let config in relatedOrdsToUpdate) {
      await updateRelatedOrders(relatedOrdsToUpdate[config]);
    }
  }

  async function updateRelatedOrders(response) {
    //console.log(response);
    if (!(response?.data)) return {};
    //console.log(`${process.env.REACT_APP_ultimateBuilderAPIEndpoint}/ordersinfo/${response.groupId}/${response.configId}`);
    let getAddlOrdersInfo = await fetch(`${process.env.REACT_APP_ultimateBuilderAPIEndpoint}/ordersinfo/${response.groupId}/${response.configId}`);
    let res = await getAddlOrdersInfo.json();
    let ordersInfo = res.ordersInfoData;
    //console.log(ordersInfo, response);
    try {
      let relatedOrdersLst = [];
      let savedGenders = [];
      let finalRelOrdsObj = {};
      let savedRelOrds = {};
      let uniqueGenders = [];
      if (response.data.savedGenderData?.length > 0) {
        // console.log("Saved gender data::: ", response.data.savedGenderData);
        // console.log("OrderInfo::: ", ordersInfo);
        savedGenders.push({
          configId: response.data.configId || response.configId,
          styleNum: response.data.styleNum || response.styleNum,
          groupId: response.data.groupId || response.groupId
        });

        savedGenders.push(...response.data.savedGenderData.map(sg => { return {
          configId: sg.configId,
          styleNum: sg.styleNum,
          groupId: parseInt(sg.groupId)
        }}));

        if (response.data.data?.rosters[0].items) {
          uniqueGenders = [...new Set(response.data.data.rosters[0].items.map(x => x.styleNum))];
        }
        // console.log("SavedGenders::: ", savedGenders, " UniqueGenders::: ", uniqueGenders);

        if (savedGenders?.length > 0 && uniqueGenders?.length > 0) {
          for (let sg1 of savedGenders) {
            let relMetaLst = [];
            for (let ug of uniqueGenders.filter(x => x === sg1.styleNum)) {
              let relatedOrdersObj = {};
              relatedOrdersObj.typeOfOrder = "Related";
              relatedOrdersObj.currentConfigId = sg1.configId;
              relatedOrdersObj.currentConfigStyleNum = sg1.styleNum;
              let ordInfo = ordersInfo?.filter((ordI) => {
                return ordI.styleNum === ug;
              }) || [];
              // console.log("OrdInfo :::: ", ordInfo);
              if (!(ordInfo?.length > 0)) {
                let ordInfo = ordersInfo?.filter((priOrdI) => {
                  return priOrdI.primaryConfigId === true;
                }) || [];
                // console.log("PriOrdInfo :::: ", priOrdInfo);

                if (ordInfo?.length === 0 && ordersInfo.length === 1) {
                  ordInfo = ordersInfo;
                }
              }
              relatedOrdersObj.relatedConfigId = ordInfo[0].configId;
              relatedOrdersObj.relatedExtOrderNo = ordInfo[0].externalorder;
              relatedOrdersObj.relatedOrderDate = ordInfo[0].createdAt;
              relatedOrdersObj.relatedOrderPO = ordInfo[0].ponumber;
              relatedOrdersObj.relatedConfigStyleNum = ordInfo[0].styleNum;
              relMetaLst.push({
                configId: sg1.configId
              });
              relatedOrdersObj.metadata = relMetaLst;
              relatedOrdersLst.push(relatedOrdersObj);
            }
          }
        }
        // console.log("FinalRelatedOrdersObj:: ", JSON.stringify(finalRelOrdsObj));
      } else {
        let relatedOrdersObj = {};
        relatedOrdersObj.typeOfOrder = "Related";
        relatedOrdersObj.currentConfigId = response.data.configId || response.configId;
        relatedOrdersObj.currentConfigStyleNum = response.data.styleNum || response.styleNum;
        relatedOrdersObj.relatedConfigId = ordersInfo[0].configId;
        relatedOrdersObj.relatedExtOrderNo = ordersInfo[0].externalorder;
        relatedOrdersObj.relatedOrderDate = ordersInfo[0].createdAt;
        relatedOrdersObj.relatedOrderPO = ordersInfo[0].ponumber;
        relatedOrdersObj.relatedConfigStyleNum = ordersInfo[0].styleNum;
        relatedOrdersObj.metadata = [{
          configId: response.data.configId
        }];
        relatedOrdersLst.push(relatedOrdersObj);
      }
      finalRelOrdsObj.relatedOrders = relatedOrdersLst;
      // console.log("FinalRelatedOrdersObj:: ", JSON.stringify(finalRelOrdsObj));
      
      if (relatedOrdersLst && relatedOrdersLst.length > 0) {
        await fetch(`${process.env.REACT_APP_ultimateBuilderAPIEndpoint}/save-related-orders`, {
          method: 'PUT',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(finalRelOrdsObj)
        });
      }
      return savedRelOrds;
    } catch (err) {
        return err;
    }
  }

  function getErrorInfo(type) {
    if (type === 'height') {
      return {
        title: 'Decoration height changes',
        info: 'Some decoration locations have a height that is not available in the selected companion styles and were resized to the closest possible height.'
      }
    }
    return {
      title: '',
      info: ''
    }
  }

  return (
    <>
      <Loader />
      <Suspense>
        <Header active="final-review" />
        <div className={classes["body"]}>
          <div className={classes["page"]}>
            <div className={classes["left"]}>
              <div className={classes["preview"]}>
                {activeItem && 
                  <StyleInfo
                    editKey={activeItem.configId}
                    showDescription={false}
                    showSelection={false}
                    showHeader={false}
                  />}
              </div>
              {Object.entries(props.productInformation.editKeyInfo).length >
                1 && (
                <div className={classes["sub-previews"]}>
                  {Object.entries(props.productInformation.editKeyInfo)
                    .sort((a, b) => sortEditKeys(a[1].data, b[1].data))
                    .map((x) => (
                      <div
                        className={
                          classes["sub-preview"] +
                          (activeItem?.configId === x[1].data.configId
                            ? " " + classes["sub-preview--active"]
                            : "")
                        }
                        onClick={() =>
                          setActiveItemByEditKey(x[1].data.configId)
                        }
                        key={x[1].data.configId}
                      >
                        <img
                          src={getPreviewImage(x[1].data, "front")}
                          alt={x[1].data.configId + " preview"}
                        />
                        <div
                          className={[
                            classes["sub-preview-status"],
                            classes["sub-preview-status--active"],
                          ].join(" ")}
                        >
                          Viewing
                        </div>
                        <div
                          className={[
                            classes["sub-preview-status"],
                            classes["sub-preview-status--inactive"],
                          ].join(" ")}
                        >
                          View
                        </div>
                        <div>{getSubPreviewName(x[1].data)}</div>
                      </div>
                    ))}
                </div>
              )}
              {getActiveRoster() && Object.entries(groupBy(getActiveRoster().errors, ({errorType}) => errorType)).map(e => 
                <div key={e[0]} className={classes["error-block"]}>
                  <div className={classes['error-block--top']}>
                    <div className={classes["error-block--left"]}>
                      <img src={alertIcon} alt="alert"/>
                    </div>
                    <div className={classes["error-block--right"]}>
                      <div className={classes["error-block--title"]}>{getErrorInfo(e[0]).title}</div>
                      <div className={classes["error-block--desc"]}>{getErrorInfo(e[0]).info}</div>
                    </div>
                  </div>
                  {e[1].map(err => <div className={classes["error-block--message"]} key={err.configId = err.styleNum}>
                    {err.message}
                    </div>)}
                </div>
              )}
            </div>
            <div className={classes["right"]}>
              <div className={classes["right-holder"]}>
                <h3>Roster Summary</h3>
                <div className={classes["order-summary"]}>
                  ${formatCurrency(getTotals().price)} total{" "}
                  <span className={classes["order-summary-dot"]}>•</span>{" "}
                  {getTotals().quantity} items total
                </div>
                {getActiveRoster() && (
                  <>
                    {getActiveRoster().roster.map((r) => (
                      <div className={classes["roster--section"]} key={r.styleNum + r.editKey}>
                        <StyleHeader
                          editKey={r.editKey}
                          styleNum={r.parentStyle}
                          overrideStyleNum={r.styleNum}
                          overrideStyleName={r.title}
                        />
                        <div className={classes["size-summary"]}>
                          <div className={classes["size-summary--title"]}>
                            Size Summary
                          </div>
                          {getActiveSizes(r).map((x) => (
                            <div
                              className={classes["size-summary--item"]}
                              key={x}
                            >
                              <b>{x}</b> ({getActiveCounts(r, x)})
                            </div>
                          ))}
                          <div
                            className={classes["size-summary--sum"]}
                            style={{ textAlign: "right", flexGrow: 1 }}
                          >
                            <b>Total</b> (
                            {getActiveSizes(r)
                              .map((x) => getActiveCounts(r, x))
                              .reduce((sum, a) => sum + a)}
                            )
                          </div>
                        </div>
                        <div className={classes["roster-holder"]}>
                          <table>
                            <thead>
                              <tr>
                                <th></th>
                                {getActiveRoster().hasPlayerName && (
                                  <th>Player Name</th>
                                )}
                                {getActiveRoster().hasPlayerNumber && (
                                  <th>Number</th>
                                )}
                                <th>Size</th>
                                <th>Qty</th>
                                <th>Price/Piece</th>
                                <th>Dec. Charge</th>
                                <th style={{width:'20%',textAlign:'right'}}>Subtotal</th>
                              </tr>
                            </thead>
                            <tbody>
                              {r.items.map((item) => (
                                <tr key={item.index}>
                                  <td></td>
                                  {getActiveRoster().hasPlayerName && (
                                    <td>{item.name}</td>
                                  )}
                                  {getActiveRoster().hasPlayerNumber && (
                                    <td>{item.number}</td>
                                  )}
                                  <td>{item.size}</td>
                                  <td>{item.qty}</td>
                                  <td>${formatCurrency(item.price)}</td>
                                  <td>${formatCurrency(item.decCharge)}</td>
                                  <td style={{textAlign:'right'}}>
                                    $
                                    {formatCurrency(
                                      item.qty * (item.price + item.decCharge)
                                    )}
                                  </td>
                                </tr>
                              ))}
                              {r.items.length === 0 ? (
                                <tr>
                                  <td colSpan="7">
                                    There are no selections for this style.
                                  </td>
                                </tr>
                              ) : null}
                            </tbody>
                          </table>
                        </div>
                        <div className={classes["style-total"]}>
                          Style Total: ${formatCurrency(getTotals(r).price)}
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer>
          <button type="button" className="footer-red" onClick={addToCart}>
            Add to cart <ShoppingCartSVG />
          </button>
        </Footer>
      </Suspense>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    customerInformation: state.customerInformation,
    productInformation: state.productInformation,
    roster: state.rosterInformation,
  };
};

export default connect(mapStateToProps)(FinalReviewPage);
