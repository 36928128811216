import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import {
  setRosterItemPartValue,
  setRosterItemValue,
} from "../../actions/roster-information";
import { useDispatch } from "react-redux";
import styles from "./roster.module.scss";
import { AiOutlineDelete } from "react-icons/ai";
import { getPrice } from "../../services/roster-helper";

function RosterLine(props) {
  const dispatch = useDispatch();
  const { register, watch, getValues, control, setValue } = useForm({
    defaultValues: props.rosterItem,
  });

  const watchGender = watch(["gender"]);
  const watchFields = watch();
  useEffect(() => {
    let values = getValues();
    if (props.rosterItem.gender !== values.gender) {
      dispatch(
        setRosterItemValue({
          index: props.index,
          field: "gender",
          value: values.gender,
          mode: props.mode,
        })
      );
    }
    if (props.rosterItem.name !== values.name) {
      dispatch(
        setRosterItemValue({
          index: props.index,
          field: "name",
          value: values.name,
          mode: props.mode,
        })
      );
    }
    if (props.rosterItem.number !== values.number) {
      dispatch(
        setRosterItemValue({
          index: props.index,
          field: "number",
          value: values.number,
          mode: props.mode,
        })
      );
    }

    let isValid = false;
    for (let i = 0; i < props.rosterItem.parts.length; i++) {
      if (+props.rosterItem.parts[i].qty > 0) {
        isValid = true;
      }
      if (props.rosterItem.parts[i].qty !== values.parts[i].qty) {
        dispatch(
          setRosterItemPartValue({
            index: props.index,
            field: "qty",
            value: values.parts[i].qty,
            mode: props.mode,
            partIndex: i,
          })
        );
      }
      if (props.rosterItem.parts[i].size !== values.parts[i].size) {
        dispatch(
          setRosterItemPartValue({
            index: props.index,
            field: "size",
            value: values.parts[i].size,
            mode: props.mode,
            partIndex: i,
          })
        );
      }
    }

    dispatch(
      setRosterItemValue({
        index: props.index,
        field: "isValid",
        value: isValid,
        mode: props.mode,
      })
    );
  }, [
    watchFields,
    dispatch,
    getValues,
    props.index,
    props.mode,
    props.rosterItem.gender,
    props.rosterItem.name,
    props.rosterItem.number,
    props.rosterItem.parts,
  ]);
  useEffect(() => {
    let g = watchGender[0];
    for (let i = 0; i < props.rosterItem.parts.length; i++) {
      let p = props.rosterItem.parts[i];
      var sizes = p.sizes.find((x) => x.gender === g).sizes;
      if (
        sizes.findIndex((x) => x.size === getValues(`parts.${i}.size`)) === -1
      ) {
        setValue(`parts.${i}.size`, sizes[0].size);
      }
    }
  }, [watchGender, getValues, props.rosterItem.parts, setValue]);

  return (
    <tr
      className={
        props.ignoreValidation === true || props.rosterItem.isValid
          ? styles["valid"]
          : styles["invalid"]
      }
    >
      {props.hasPlayerName && (
        <td>
          <input type="text" {...register("name")} />
        </td>
      )}
      {props.hasPlayerNumber && (
        <td>
          <input type="text" {...register("number")} />
        </td>
      )}
      <td>
        <select {...register("gender")}>
          {props.rosterItem.genders.map((g, i) => {
            return (
              <option key={g} value={g}>
                {g}
              </option>
            );
          })}
        </select>
      </td>
      {props.rosterItem.parts.map((ri, i) => (
        <RosterLinePart
          key={i}
          control={control}
          register={register}
          index={i}
          rosterItemPart={ri}
          getValues={getValues}
          gender={props.rosterItem.gender}
          watch={watch}
        />
      ))}
      <td>
        <button
          type="button"
          className={styles["roster-button"] + " " + styles["delete-button"]}
          onClick={() => props.deleteItem(props.index)}
        >
          <AiOutlineDelete />
        </button>
      </td>
    </tr>
  );
}

function RosterLinePart(props) {
  props.watch([
    `parts.${props.index}.size`,
    `parts.${props.index}.qty`,
    "gender",
  ]);

  return (
    <>
      <td></td>
      <td>
        <select
          {...props.register(`parts.${props.index}.size`)}
        >
          {props.rosterItemPart.sizes
            .find((x) => x.gender === props.getValues(`gender`))
            .sizes.map((s, i) => {
              return (
                <option key={s.size} value={s.size}>
                  {s.size}
                </option>
              );
            })}
        </select>
      </td>
      <td>
        <input
          type="number"
          min="0"
          {...props.register(`parts.${props.index}.qty`)}
        />
      </td>
      <td>${getPrice(props.index, props.getValues, props.rosterItemPart, "full")}</td>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    roster: state.rosterInformation,
  };
};

export default connect(mapStateToProps)(RosterLine);
