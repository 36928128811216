import { configureStore } from "@reduxjs/toolkit";
import customerInformation from "./customer-information";
import productInformation from "./product-information";
import rosterInformation from "./roster-information";
import iframe from "./iframe";

export default configureStore({
    reducer: {
        customerInformation: customerInformation,
        productInformation: productInformation,
        rosterInformation: rosterInformation,
        iframe: iframe
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
})