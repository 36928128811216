import { useCallback } from "react";
import { connect } from "react-redux";

function ErrorPage(props) {
  const getErrors = useCallback(() => {
    let errorStatus = {
      valid: props.productInformation.errors.length === 0,
      errorMessage: props.productInformation.errors,
    };
    return errorStatus;
  }, [props.productInformation.errors]);
  return (
    <div className="body">
      <h1>Error Page</h1>
      {!getErrors().valid ? <div>{getErrors().errorMessage.join(' ')}</div> : <div></div>}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    customerInformation: state.customerInformation,
    productInformation: state.productInformation,
    roster: state.rosterInformation,
  };
};

export default connect(mapStateToProps)(ErrorPage);
