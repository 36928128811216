import { createSlice } from "@reduxjs/toolkit";

export const customerInformation = createSlice({
    name: 'customer-information',
    initialState: {
        customerNumber: null,
        iframeLoaded: false,
        useRoster: null,
        currentMode: null
    },
    reducers: {
        setCustomerNumber: (state, action) => {
            state.customerNumber = action.payload;
        },
        setIframeLoaded: (state, action) => {
            state.iframeLoaded = action.payload;
        },
        setUseRoster: (state, action) => {
            state.useRoster = action.payload;
        },
        setCurrentMode: (state, action) => {
            state.currentMode = action.payload;
        }
    }
});

export const {
    setCustomerNumber,
    setIframeLoaded,
    setUseRoster,
    setCurrentMode
} = customerInformation.actions;

export default customerInformation.reducer;