import { connect } from "react-redux";
import styles from "./style-header.module.scss";
import {ReactComponent as HomeBottomIcon} from '../../Assets/icons/home-bottom-icon.svg';
import {ReactComponent as HomeTopIcon} from '../../Assets/icons/home-top-icon.svg';
import {ReactComponent as AwayBottomIcon} from '../../Assets/icons/away-bottom-icon.svg';
import {ReactComponent as AwayTopIcon} from '../../Assets/icons/away-top-icon.svg';

function StyleHeader(props) {
  const styleInfo = props.productInformation.styleInfo[props.styleNum ?? props.overrideStyleNum];
  const editKey = props.productInformation.editKeyInfo[props.editKey];

  let getIcon = () => {
    if (editKey.isHome) {
      if (editKey.isBottom) {
        return <HomeBottomIcon/>;
      } else {
        return <HomeTopIcon/>;
      }
    } else if (editKey.isAway) {
      if (editKey.isBottom) {
        return <AwayBottomIcon/>;
      } else {
        return <AwayTopIcon/>;
      }
    }
    return <span></span>;
  }
  return (
    <>
      <div className={styles["style-header--title"]}>
        <span>{props?.overrideStyleNum ?? styleInfo?.metadata.Style}</span>
        <div style={{marginRight: "20px"}}>
          <img
            src={`https://marketing.foundersportgroup.com/Images/Brand_Images/${styleInfo?.value.Brand}.png`}
            alt={styleInfo?.value.Brand}
          />
        </div>
        {
          (editKey.isHome || editKey.isAway || editKey.isTop || editKey.isBottom) &&
          <span className={styles["style-header--homeaway-note"]}>
            {editKey.isHome && "Home"}{editKey.isAway && "Away"}&nbsp;
            {editKey.isTop && "Top"} {editKey.isBottom && "Bottom"}&nbsp;
            {getIcon()}
          </span>
        }
      </div>
      <div className={styles["style-header--subtitle"]}>
        {props?.overrideStyleName ?? styleInfo?.metadata.Title}
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    productInformation: state.productInformation,
  };
};

export default connect(mapStateToProps)(StyleHeader);
