import { connect, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { sortEditKeys } from "../../shared/sorting";
import { setRosterItemPartValue } from "../../actions/roster-information";
import { getFinalPrices, getPrice, validateModeRoster } from "../../services/roster-helper";
import { useForm } from "react-hook-form";
import styles from "./roster-stock.module.scss";
import groupBy from 'core-js/actual/object/group-by';
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import StyleInfo from "../select-styles/style-info";
import RightArrowSVG from "../../Assets/icons/RightArrowSVG";
import { setCurrentMode } from "../../actions/customer-information";

function RosterStockPage(props) {
  const dispatch = useDispatch();
  const [queryParameters] = useSearchParams();
  const mode = queryParameters.get("mode");
  const rosterItems = props.roster.rosterItems[mode];
  const navigate = useNavigate();

  let [hasTops, setHasTops] = useState(null);
  let [hasBottoms, setHasBottoms] = useState(null);

  useEffect(() => {
    console.log(mode);
    dispatch(setCurrentMode(mode));
}, [mode, dispatch])

  let eki = {};
  let changedTop = false,
    changedBottom = false;
  if (props.productInformation.editKeyInfo) {
    for (const ki in props.productInformation.editKeyInfo) {
      if (!props.productInformation.editKeyInfo[ki].selected) continue;
      let i = props.productInformation.editKeyInfo[ki];
      if (
        (mode === "home" && (i.isHome === true || i.isHome === undefined || (i.isHome === false && i.isHome === false))) ||
        (mode === "away" && i.isAway === true)
      ) {
        eki[ki] = i;
        if (i.isTop === true && hasTops !== true) {
          setHasTops(true);
          changedTop = true;
        }
        if (i.isBottom === true && hasBottoms !== true) {
          changedBottom = true;
          setHasBottoms(true);
        }
      }
    }
  }
  if (changedTop === false && hasTops === null) setHasTops(false);
  if (changedBottom === false && hasBottoms === null) setHasBottoms(false);
  const editKeyInfo = eki;

  function reviewSummaryClick() {
    if (validateModeRoster(mode, props.roster.rosterItems, true)) {
      navigate("/final-review");
    }
  }
  function awayRosterClick() {
    if (validateModeRoster(mode, props.roster.rosterItems, false)) {
      navigate("/roster-stock?mode=away");
    }
  }

  return (
    <>
      <Header active="roster" />
      <div className={[styles.body].join(' ')}>
        {editKeyInfo &&
          Object.entries(editKeyInfo)
            .sort((a, b) => sortEditKeys(a[1].data, b[1].data))
            .map((item) => (
              <div className={styles['item-row']} key={item[1].data.configId}>
                <div className={styles.left}>
                  <StyleInfo
                    key={item[1].data.configId}
                    editKey={item[1].data.configId}
                    showDescription={false}
                    showSelection={false}
                  />
                </div>
                <div className={styles.right}>
                  {Object.entries(
                    groupBy(
                      getFinalPrices(item[1].data),
                      ({ gender }) => gender
                    )
                  ).map((subitem) => (
                    <div className={styles['gender-section']} key={subitem[0]}>
                      <h3>{subitem[0]}</h3>
                      <div className={styles.rosters}>
                        {subitem[1].map((x, i) => {
                          let ri = rosterItems.findIndex((y) => {
                            return (
                              y.gender === x.gender &&
                              y.parts[0].size === x.size &&
                              y.parts[0].editKey.configId ===
                                item[1].data.data.configId
                            );
                          });
                          if (ri === -1) {
                            return <div key={"temp" + i}></div>;
                          }

                          return (
                            <StockRosterLine
                              key={x.sku}
                              mode={mode}
                              index={ri}
                              rosterItem={rosterItems[ri]}
                            />
                          );
                        })}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
      </div>
      <Footer>
        {props.productInformation.editKeyTypes.away === true &&
          mode === "home" && (
            <button type="button" className="footer-red" onClick={awayRosterClick}>
              Enter Away Roster <RightArrowSVG />
            </button>
          )}
        {(mode === "away" ||
          (props.productInformation.editKeyTypes.away === false &&
            mode === "home")) && (
          <button type="button" className="footer-red" onClick={reviewSummaryClick}>
            Review Summary <RightArrowSVG />
          </button>
        )}
      </Footer>
    </>
  );
}

function StockRosterLine(props) {
  //index, mode, rosterItem
  const dispatch = useDispatch();
  const { register, watch, getValues } = useForm({
    defaultValues: props.rosterItem,
  });
  const watchFields = watch("parts.0.qty");

  useEffect(() => {
    let values = getValues();
    if (props.rosterItem.qty !== values.parts[0].qty) {
      dispatch(
        setRosterItemPartValue({
          index: props.index,
          field: "qty",
          value: values.parts[0].qty,
          mode: props.mode,
          partIndex: 0,
        })
      );
    }
  }, [
    watchFields,
    dispatch,
    getValues,
    props.index,
    props.mode,
    props.rosterItem?.parts,
    props.rosterItem?.qty,
  ]);

  return (
    <div className={styles['roster-block']}>
      <div className={styles["roster-title"]}>
        <span className={styles["size-block"]}>{props.rosterItem.parts[0].size}</span>
        <span className={styles["price-block"]}>
          ${getPrice(0, getValues, props.rosterItem.parts[0], "full")}
        </span>
      </div>
      <input
        type="number"
        // pattern="[0-9]*"
        // inputMode="numeric"
        step="1"
        className={styles["part-xsmall"]}
        min="0"
        {...register(`parts.0.qty`)}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    customerInformation: state.customerInformation,
    productInformation: state.productInformation,
    roster: state.rosterInformation,
  };
};

export default connect(mapStateToProps)(RosterStockPage);
