import { createSlice } from "@reduxjs/toolkit";

export const iframe = createSlice({
    name: 'iframe-handler',
    initialState: {
        message: null
    },
    reducers: {
        sendMessage: (state, action) => {
            state.message = action.payload;
        },
        clearMessage: (state, action) => {
            state.message = null;
        }
    }
});

export const {
    sendMessage,
    clearMessage
} = iframe.actions;

export default iframe.reducer;