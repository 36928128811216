import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import classes from "./footer.module.scss";
import { usePopper } from "react-popper";
import {ReactComponent as DownloadIcon} from '../../Assets/icons/download-outline.svg';

function Footer(props) {
  const sti = props.productInformation.styleInfo;
  const eki = props.productInformation.editKeyInfo;

  const [leadTime, setLeadTime] = useState(null);
  const [expediteTime, setExpediteTime] = useState(null);
  const [is5Day, set5Day] = useState(false);

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "top",
    modifiers: [
      { name: "arrow", options: { element: arrowElement } },
      { name: "offset", options: { offset: [0, 10] } },
    ],
  });

  const getLeadTime = useCallback(() => {
    let lead = null;
    let fiveDay = false;

    for (const editKey in eki) {
      if (eki[editKey].selected !== true) continue;

      let styleInfo = sti[eki[editKey].data.styleNum];
      if (!styleInfo) continue;
      //TODO: quantity > 199 && productType && productType === "Stock" ? "TBD"
      let type = null;

      if (styleInfo.value.Type === "Stock") {
        type = "Screen Print Logos";

        let locs = eki[editKey].data.data.Locations.concat(
          eki[editKey].data.data.insideLocations
        );
        if (
          (locs.findIndex((x) => x.contentType === "Custom Text") === -1) &
          ([...new Set(locs.map((x) => x.contentType))].length > 1)
        ) {
          if (locs.findIndex((x) => x.textType === "Duratek")) {
            type = "Screen Print Logos, DuraTEK Player Names and/or Numbers";
          } else {
            type =
              "Screen Print Logos, Screen Print Player Names and/or Numbers";
          }
        }
      } else if (styleInfo.value.Type === "Sublimated") {
        if (styleInfo?.metadata && styleInfo.metadata.Program) {
          if (styleInfo.metadata.Program === "5 Day") {
            type = "5 Day Tech Tees";
            fiveDay = true;
          } else {
            type = "Chromagear Sublimation";
          }
        } else {
          type = "Chromagear Sublimation";
        }
      }
      if (styleInfo?.leadTime?.availableLeadTimes?.length > 0) {
        let leadTimeData = styleInfo.leadTime.availableLeadTimes.filter(
          (x) => x.decorationMethod === type
        );
        if (
          lead !== "TBD" &&
          leadTimeData.length > 0 &&
          leadTimeData[0].currentLeadTime > lead
        ) {
          lead = leadTimeData[0].currentLeadTime;
        } else if (leadTimeData.length === 0) {
          lead = "TBD";
        }
      }
    }
    if (leadTime !== lead) {
      setLeadTime(lead);
    }
    if (fiveDay !== is5Day) {
      set5Day(fiveDay);
    }
  }, [eki, sti, leadTime, is5Day]);

  useEffect(() => {
    let newExpediteTime = null;
    if (eki && sti && leadTime && leadTime !== "TBD" && is5Day === false) {
      if (Object.keys(sti).length > 0) {
        var s = sti[Object.keys(sti)[0]];
        if (s.expediteInfo && s.expediteInfo.ExpediteDetails) {
          if (
            s.expediteInfo.ExpediteDetails.Expedite_Program_Active === "true"
          ) {
            newExpediteTime =
              parseInt(leadTime) -
              parseInt(s.expediteInfo.ExpediteDetails.Expedite_Bonus_Days);
          }
        }
      }
    }
    if (expediteTime !== newExpediteTime) {
      setExpediteTime(newExpediteTime);
    }
  }, [eki, sti, leadTime, expediteTime, is5Day]);

  useEffect(() => {
    if (
      eki &&
      sti &&
      Object.keys(eki).length > 0 &&
      Object.keys(sti).length > 0
    ) {
      getLeadTime();
    } else {
      setLeadTime(null);
    }
  }, [sti, eki, getLeadTime]);

  const showTooltip = () => {
    popperElement.setAttribute("data-show", true);
  };

  const hideTooltip = () => {
    popperElement.removeAttribute("data-show");
  };

  let downloadPdf = () => {
    fetch(process.env.REACT_APP_PDFGeneratorAPIEndpoint, {
      method: 'POST',
      body: JSON.stringify({
        configurationType: Object.entries(props.productInformation.styleInfo)[0][1].value.Type,
        projectConfigs: Object.keys(eki)
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
      },
    })
    .then((response) => response.blob())
    .then((blob) => {
      // Build a URL from the file
      let fileURL = URL.createObjectURL(blob);
      // Open the URL on new Window
      let customerMockup = document.createElement("a");
      customerMockup.href = fileURL;
      customerMockup.setAttribute("download", "CustomerMockup.pdf");
      document.body.appendChild(customerMockup);
      // Start download
      customerMockup.click();
      customerMockup.parentNode.removeChild(customerMockup);
    });
  }

  return (
    <>
      <footer className={classes["footer"]}>
        <div className={classes["footer--section"]} style={{flexGrow: 0, flexShrink: 1}}>
          <button type="button" onClick={downloadPdf}>Download PDF <DownloadIcon/></button>
        </div>
        {leadTime && (
          <div className={classes["footer--section"]}>
            <div className={classes["footer--subtitle"]}>Lead Time</div>
            <div className={classes["big-day"]}>{leadTime}</div>
            <div>
              Business<br></br>Days
            </div>
            {expediteTime && leadTime !== expediteTime && (
              <>
                <div
                  className={classes["footer-subtitle"]}
                  style={{ paddingLeft: 0 }}
                >
                  Or
                </div>
                <div className={classes["big-day"]}>{expediteTime}</div>
                <div>
                  <span className={classes["footer--rush"]}>
                    <span className={classes["footer--rushTitle"]}>
                      Rush Service
                    </span>
                    <span
                      className={classes["footer--rushIcon"]}
                      onMouseEnter={showTooltip}
                      onMouseLeave={hideTooltip}
                      onFocus={showTooltip}
                      onBlur={hideTooltip}
                      ref={setReferenceElement}
                    >
                      <AiOutlineQuestionCircle />
                    </span>
                  </span>
                  Available
                </div>

                <div
                  ref={setPopperElement}
                  className={classes["tooltip"]}
                  style={styles.popper}
                  {...attributes.popper}
                >
                  Adding an uploaded graphic to your 5 Day product might
                  increase the turnaround time by a few days as our ship date
                  will be determined after your logo is reviewed. Supplying
                  artwork in vector format, and supplying detailed information
                  around colors, will help speed up the process.
                  <div
                    ref={setArrowElement}
                    style={styles.arrow}
                    className={classes["arrow"]}
                  />
                </div>
              </>
            )}
          </div>
        )}
        <div className={classes["footer--section"]}>
          <div className={classes["footer--subtitle"]}>Next Step</div>
          <div>{props.children}</div>
        </div>
      </footer>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    customerInformation: state.customerInformation,
    productInformation: state.productInformation,
  };
};

export default connect(mapStateToProps)(Footer);
