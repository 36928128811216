import { connect } from "react-redux";
import styles from './loader.module.scss';
import { useEffect, useState } from "react";

function Loader(props) {

    let [opacity, setOpacity] = useState(0);

    useEffect(() => {
      setOpacity(props.productInformation.loadStatus !== "loaded" ? 1 : 0);
    }, [props.productInformation.loadStatus])
    

    return (
        opacity > 0 &&
    <div className={styles["loaderOverlay"]} style={{opacity: opacity}}>
        <div className={styles["messageBox"]}>
            <div className={styles["message"]}>{props.productInformation.loadStatus}</div>
        </div>
        <div className={styles["threekitLoader"]}></div>
    </div>)
}

const mapStateToProps = (state) => {
    return {
        customerInformation: state.customerInformation,
        productInformation: state.productInformation
    }
}

export default connect(mapStateToProps)(Loader);