const previewPriority = ["HOME TOP", "HOME BOTTOM", 'AWAY TOP', "AWAY BOTTOM"];

const sortEditKeys = function(a, b) {
    let nameA = getGroupType(a);
    let nameB = getGroupType(b);
    if (!nameA) return -1;
    if (!nameB) return 1;
    nameA = previewPriority.indexOf(nameA.toUpperCase());
    nameB = previewPriority.indexOf(nameB.toUpperCase());
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;

    return 0;
}

const getGroupType = function(editKey) {
    if (!editKey.groupType) return null;
    return editKey.groupType.find(x => x.savedConfig === editKey.configId)?.type;
}

export {
    sortEditKeys,
    getGroupType
};