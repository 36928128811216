import { connect, useDispatch } from "react-redux";
import StyleInfo from "./style-info";
import { useState } from "react";
import { setEditKeySelection } from "../../actions/product-information";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import { useNavigate } from "react-router-dom";
import RightArrowSVG from "../../Assets/icons/RightArrowSVG";
import Loader from "../../components/loader/loader";
import './select-styles.scss';

function SelectStylesPage(props) {
    const [editKeys, ] = useState(props.productInformation.editKeys);
    let dispatch = useDispatch();
    const navigate = useNavigate();
    let setSelectionState = (editKeyId, state) => {
        dispatch(setEditKeySelection({
            editKey: editKeyId,
            selected: state
        }));
    }
    let navigateToRoster = () => {
        let hasSelection = false;
        for (const editInfo in props.productInformation.editKeyInfo) {
            if (props.productInformation.editKeyInfo[editInfo].selected === true) {
                hasSelection = true;
            }
        }
        if (!hasSelection) {
            alert('At least one item must be selected.');
            return false;
        }
        navigate("/roster");
    }
    return (<>
        <Header active="select-styles"/>
        <div className="body">
            <div className="select-styles">
                {editKeys.map((editKey) =>{
                    return <StyleInfo key={editKey} editKey={editKey} setSelectionState={setSelectionState}/>
                })}
            </div>
            <Loader/>
        </div>
        <Footer>
            <button type="button" className="footer-red" onClick={navigateToRoster}>Enter Roster <RightArrowSVG/></button>
        </Footer>
    </>);
}

const mapStateToProps = (state) => {
    return {
        customerInformation: state.customerInformation,
        productInformation: state.productInformation
    }
}

export default connect(mapStateToProps)(SelectStylesPage);