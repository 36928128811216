import { connect } from "react-redux";
import styles from "./header.module.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { validateModeRoster } from "../../services/roster-helper";

function Header(props) {
  const navigate = useNavigate();
  const [menuPieces] = useState({
    singleRoster: [
      {
        order: 2,
        name: "Complete Roster",
        type: "roster",
        active: "roster?mode=home",
      },
    ],
    singleStockRoster: [
      {
        order: 2,
        name: "Complete Roster",
        type: "roster",
        active: "roster-stock?mode=home",
      },
    ],
    dualRoster: [
      {
        order: 2,
        name: "Complete Home Roster",
        type: "roster",
        active: "roster?mode=home",
      },
      {
        order: 2,
        name: "Complete Away Roster",
        type: "roster",
        active: "roster?mode=away",
      },
    ],
    dualStockRoster: [
      {
        order: 2,
        name: "Complete Home Sizes",
        type: "roster",
        active: "roster-stock?mode=home",
      },
      {
        order: 2,
        name: "Complete Away Sizes",
        type: "roster",
        active: "roster-stock?mode=away",
      },
    ],
    prefix: [],
    suffix: [
      {
        order: 10,
        type: "final-review",
        name: "Final Review",
        active: "final-review",
      },
    ],
  });
  let [buttonList, setButtonList] = useState([]);
  let [currentActive, setCurrentActive] = useState(null);

  function navigateClick(button) {
    if (button.type === "final-review") {
      if (validateModeRoster(props.customerInformation.currentMode, props.roster.rosterItems, true)) {
        navigate("/" + button.active);
      }
    } else {
      navigate("/" + button.active);
    }
  }

  useEffect(() => {
    var newCurrentActive = buttonList.find((x) => {
      return x.type === props.active;
    });
    setCurrentActive(newCurrentActive);
  }, [props.active, buttonList]);

  useEffect(() => {
    let rosterMenu = [];
    if (
      props.productInformation.editKeyTypes.playerName ||
      props.productInformation.editKeyTypes.playerNumber
    ) {
      if (
        props.productInformation.editKeyTypes.home &&
        props.productInformation.editKeyTypes.away
      ) {
        rosterMenu = menuPieces.dualRoster;
      } else {
        rosterMenu = menuPieces.singleRoster;
      }
    } else {
      if (
        props.productInformation.editKeyTypes.home &&
        props.productInformation.editKeyTypes.away
      ) {
        rosterMenu = menuPieces.dualStockRoster;
      } else {
        rosterMenu = menuPieces.singleStockRoster;
      }
    }
    setButtonList([...menuPieces.prefix, ...rosterMenu, ...menuPieces.suffix]);
  }, [
    props.productInformation.editKeyTypes.home,
    props.productInformation.editKeyTypes.away,
    props.productInformation.editKeyTypes.playerName,
    props.productInformation.editKeyTypes.playerNumber,
    menuPieces.prefix,
    menuPieces.dualRoster,
    menuPieces.singleRoster,
    menuPieces.dualStockRoster,
    menuPieces.singleStockRoster,
    menuPieces.suffix,
  ]);

  return (
    <header className={styles["header"]}>
      <nav className={styles["nav"]}>
        {buttonList.length > 0 &&
          currentActive &&
          buttonList.map((button) => {
            return (
              <button
                key={button.active}
                className={`${
                  (window.location.pathname + window.location.search ===
                  "/" + button.active
                    ? styles["is-active"]
                    : "") +
                  " " +
                  styles["button"]
                }`}
                onClick={() => navigateClick(button)}
              >
                {button.name}
              </button>
            );
          })}
      </nav>
    </header>
  );
}

const mapStateToProps = (state) => {
  return {
    customerInformation: state.customerInformation,
    productInformation: state.productInformation,
    roster: state.rosterInformation,
  };
};

export default connect(mapStateToProps)(Header);
